import { invalidMapper, answeredMapper, dummyMapper } from "../dashHelper";
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from "./commonWidgetsCaffenio";

export default {
  title: 'Apertura Dashboard',
  description: 'Apertura Default',
  widgets: (filters: FiltersUI) => [

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: "Satisfacción General",
      position: [0, 0],
      size: [4, 3],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'apertura_pregunta_1',
      criticalMomentId: 10252
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Satisfacción General',
      position: [0, 3],
      size: [1, 2],
      indicator: 'apertura_pregunta_1',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Disposición a comprar',
      position: [1, 3],
      size: [1, 2],
      indicator: 'apertura_pregunta_5',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'Disposición a retornar',
      position: [2, 3],
      size: [1, 2],
      indicator: 'apertura_pregunta_4',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: '% Registros válidos para envío de encuestas',
      position: [3, 3],
      size: [1, 2],
      criticalMomentId: 10252,
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: '% tasa de respuesta',
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [0, 5],
      size: [1, 2],
      criticalMomentId: 10252,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa puntos de ventas',
      position: [1, 5],
      size: [3, 2],
      criticalMomentId: 10252,
      indicator: 'apertura_pregunta_1',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Satisfacción: Clientes insatisfechos',
      position: [0, 7],
      size: [2, 2],
      indicator: 'apertura_pregunta_2',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Conocimiento de la apertura',
      position: [2, 7],
      size: [2, 2],
      indicator: 'apertura_pregunta_6',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Medio por el cual conoce el nuevo establecimiento',
      position: [0, 9],
      size: [2, 2],
      indicator: 'apertura_pregunta_7',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Adjetivo: Nueva sucursal',
      position: [2, 9],
      size: [2, 2],
      indicator: 'apertura_pregunta_8',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Donde consumió anteriormente',
      position: [0, 11],
      size: [2, 2],
      indicator: 'apertura_pregunta_9',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Tipo de Café consumes',
      position: [2, 11],
      size: [2, 2],
      indicator: 'apertura_pregunta_11',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Tienda de conveniencia',
      position: [0, 13],
      size: [2, 2],
      indicator: 'apertura_pregunta_12',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Temporalidad con Caffenio',
      position: [2, 13],
      size: [2, 2],
      indicator: 'apertura_pregunta_15',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Momento del día',
      position: [0, 15],
      size: [2, 2],
      indicator: 'apertura_pregunta_18',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Tiempo de traslado',
      position: [2, 15],
      size: [2, 2],
      indicator: 'apertura_pregunta_19',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Colonia dónde vienes',
      position: [0, 17],
      size: [2, 2],
      indicator: 'apertura_pregunta_38',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Colonia dónde vas',
      position: [2, 17],
      size: [2, 2],
      indicator: 'apertura_pregunta_51',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Dónde te diriges',
      position: [0, 19],
      size: [2, 2],
      indicator: 'apertura_pregunta_20',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Facilidad al usar la pantalla',
      position: [2, 19],
      size: [2, 2],
      indicator: 'apertura_pregunta_25',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: 'Le atendieron rápidamente',
      position: [0, 21],
      size: [1, 2],
      mapper: dummyMapper,
      indicator: 'apertura_pregunta_27',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: 'Le atendieron con amabilidad',
      position: [1, 21],
      size: [1, 2],
      mapper: dummyMapper,
      indicator: 'apertura_pregunta_29',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Encontró el producto que buscaba',
      position: [2, 21],
      size: [2, 2],
      indicator: 'apertura_pregunta_30',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: '¿Qué producto te gustaría que agregáramos a nuestro menú?',
      position: [0, 23],
      size: [2, 2],
      indicator: 'apertura_pregunta_42',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCount',
      title: 'Describe a detalle el producto que te gustaría que agregaramos a nuestro menú',
      position: [2, 23],
      size: [2, 2],
      indicator: 'apertura_pregunta_39',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: 'Utilizaste servicio Drive',
      position: [0, 25],
      size: [1, 2],
      mapper: dummyMapper,
      indicator: 'apertura_pregunta_24',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieSiNo',
      title: 'Género',
      position: [1, 25],
      size: [1, 2],
      mapper: dummyMapper,
      indicator: 'apertura_pregunta_33',
      criticalMomentInclude: [10252],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: 'Edad',
      position: [2, 25],
      size: [2, 2],
      indicator: 'apertura_pregunta_34',
      criticalMomentInclude: [10252]
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Analisis de Sentimientos',
      position: [0, 27],
      size: [4, 4],
      indicator: "SENTIMENT",
      criticalMomentId: 10252
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 31],
      size: [4, 2],
      criticalMomentId: 10252,
    }),

  ]
}
