import { renderForCriticalMoment } from '../dashHelper'
import durante from './durante'
import posterior from './posterior'
import defaultWidgets from './default'

export default {
  title: 'Demo Dashboard',
  description: 'Dashboard de perfil Demo',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    ...defaultWidgets.widgets(filters)
      .map((wdg) => ({
        ...wdg,
        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [29]),
      })),
    ...durante.widgets(filters)
      .map((wdg) => {
        return ({
          ...wdg,
          render: (filters: FiltersUI) => renderForCriticalMoment(filters, [9951]),
        })
      }),
    ...posterior.widgets(filters)
      .map((wdg) => {

        return ({
          ...wdg,
          render: (filters: FiltersUI) => renderForCriticalMoment(filters, [10301]),
        })
      }),
  ],
} as DasboardConfig
