import { getCommonWidget } from "../../dashWidgetHelper";
import { commonWidgets, getProfuturoFacilidad, getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoSatisfaccion } from "../commonWidgetsProfuturo";

export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    {
      title: 'Principales Indicadores',
      position: [0, 0],
      size: [4, 1],
      type: 'label',
      config: { color: '#B7AADC' },
    },
    getProfuturoNpsAcumulado({
      position: [0, 1],
      size: [1, 2],
      indicator: 'est_cuenta_pregunta_14'
    }),
    getProfuturoNpsPorMes({
      position: [1, 1],
      size: [1, 2],
      indicator: 'est_cuenta_pregunta_14',
    }),
    getProfuturoSatisfaccion({
      position: [2, 1],
      size: [1, 2],
      indicator: 'est_cuenta_pregunta_2',
      helpText: '¿Qué tan satisfecho te encuentras con tu Estado de Cuenta?'
    }),
    getProfuturoFacilidad({
      position: [3, 1],
      size: [1, 2],
      indicator: 'est_cuenta_pregunta_7',
      helpText: '¿qué tan fácil te resultó?'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de Sentimientos',
      position: [0, 3],
      size: [4, 4],
      indicator: 'SENTIMENT'
    }),
  ]
} as DasboardConfig
