import { COLORS_CONFIG_BAR, COLORS_CONFIG_GAUGE_5 } from '@components/widgets/apacheEchart/Commons'
import { answeredMapper, invalidMapper } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import {
  commonWidgets,
} from './commonWidgetsByd'
import { bydCustomerJourneyVentaMapper, bydTooltipTotalEncuestas } from './cxoResume'

export default {
  title: 'Nacional Venta Dashboard',
  description: 'Dashboard de Nacional Venta',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [0, 0],
      size: [1, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      extraConfigs: {
        decimals: 1 
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'semicircleGeneric',
      title: 'widgets.npsVenta',
      position: [1, 0],
      size: [1, 2],
      indicator: 'VENTA_NPS',
      criticalMomentId: 1,
      extras: {
        periods: 6,
        decimals: 1
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      position: [2, 0],
      size: [1, 2],
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper,
      tooltipFormatter: bydTooltipTotalEncuestas,
      criticalMomentId: 1,
      extraConfigs: {
        decimals: 1 
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      position: [3, 0],
      size: [1, 2],
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      tooltipFormatter: bydTooltipTotalEncuestas,
      criticalMomentId: 1,
      extraConfigs: {
        decimals: 1 
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      position: [0, 2],
      size: [4, 2],
      mapper: bydCustomerJourneyVentaMapper,
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 1,
      extraConfigs: {
        decimals: 1,
        colors: COLORS_CONFIG_GAUGE_5,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsVenta',
      position: [0, 4],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsVenta',
      position: [1, 4],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasVenta',
      position: [2, 4],
      size: [1, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1,
      extraConfigs: {
        fontSize: 20
      },
      extras: {
        featureExclude: ['groupByTopLocation'],
        groupNameFormat: 'description',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasVenta',
      position: [3, 4],
      size: [1, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1,
      extraConfigs: {
        fontSize: 20
      },
      extras: {
        featureExclude: ['groupByTopLocation'],
        groupNameFormat: 'description',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsVenta',
      position: [0, 6],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: 1,
      extras: {
        resultType: 'top',
        trunc: "HALF_DOWN",
        topSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsVenta',
      position: [2, 6],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: 1,
      extras: {
        resultType: 'bottom',
        trunc: "HALF_UP",
        bottomSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de sentimientos',
      indicator: 'SENTIMENT',
      criticalMomentId: 1,
      position: [0, 7],
      size: [4, 4],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'widgets.zonalSatisfaction',
      key: 'venta-satisfaccion-zona',
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      position: [0, 11],
      size: [4, 2],
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        wrapTexts: true
      },
      extras: {
        groupByLevel: 2
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'widgets.distribuidorSatisfaction',
      key: 'venta-satisfaccion-dist',
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      position: [0, 13],
      size: [4, 2],
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        wrapTexts: true,
        rotateXAxisLegend: true
      },
      extras: {
        groupByLevel: 3,
      },
    }),
  ],
} as DasboardConfig
