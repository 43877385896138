import { COLORS_CONFIG_BAR } from "@components/widgets/apacheEchart/Commons"
import { answeredMapper, invalidMapper } from "../dashHelper"
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, getCmID, suzukiCustomPieMapper } from "./commonWidgetsSuzuki"

export default {
  title: "Nacional Venta Dashboard",
  description: "Dashboard de Nacional Venta",
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets:(filters: FiltersUI) =>  [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: getCmID("venta"),
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [0, 4],
      size: [1, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsVenta',
      indicator: 'VENTA_NPS',
      position: [1, 4],
      size: [1, 2],
      criticalMomentId: getCmID("venta"),
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      position: [2, 4],
      size: [1, 2],
      criticalMomentId: getCmID("venta"),
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [3, 4],
      size: [1, 2],
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label: 'widgets.indicatorId.' + item.groupId
          }
        })
      },
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      position: [0, 6],
      size: [4, 2],
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasVenta',
      position: [0, 8],
      size: [2, 2],
      extraConfigs: { fontSize: '22px' },
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasVenta',
      position: [2, 8],
      size: [2, 2],
      extraConfigs: { fontSize: '22px' },
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'widgets.geoLocationMap',
      position: [0, 10],
      size: [2, 2],
      criticalMomentId: getCmID("venta"),
      indicator: 'VENTA_NPS',
    }),
   
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsVenta',
      position: [2, 10],
      size: [1, 2],
      extraConfigs: {
        fontSize: '18px',
        //calculatedLimit: (data: any) => Math.min(3, (data.length) / 2),
        limit: 3
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsVenta',
      position: [3, 10],
      size: [1, 2],
      extraConfigs: {
        fontSize: '18px',
        //calculatedLimit: (data: any) => Math.min(3, (data.length) / 2),
        limit: 3
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsVenta',
      position: [0, 12],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("venta"),
      extras: {
        resultType: 'top',
        trunc: "HALF_DOWN",
        topSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsVenta',
      position: [2, 12],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("venta"),
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 13],
      size: [4, 4],
      indicator: 'SENTIMENT',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: "widgets.zonalSatisfaction",
      key: 'venta-satisfaccion-zona',
      indicator: "VENTA_SATISFACCION",
      criticalMomentId: getCmID("venta"),
      position: [0, 17],
      size: [4, 2],
      extraConfigs: {
        colors: COLORS_CONFIG_BAR
      },
      extras: {
        groupByLevel: 2,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: "widgets.distribuidorSatisfaction",
      key: "venta-satisfaccion-dist",
      indicator: "VENTA_SATISFACCION",
      criticalMomentId: getCmID("venta"),
      position: [0, 19],
      size: [4, 2],
      mapper: (data: any, extra: any) => {
        console.log('venta-satisfaccion-dist')
        console.log(data)
        //return data
        return data.map((item: any) => {
          return {
            ...item,
            label: item.label !== undefined ? item.label.trim().replace('SUZUKI ', '') : undefined,
            group: item.group !== undefined ? item.group.trim().replace('SUZUKI ', '') : undefined
          }
        })
      },
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        rotateXAxisLegend: true,
      },
      extras: {
        groupByLevel: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: "widgets.driveTestReasonsVenta",
      position: [0, 21],
      size: [2, 2],
      indicator: "VENTA_SIN_PRUEBA_MANEJO_MOTIVO",
      criticalMomentId: getCmID("venta"),
      extraConfigs: {
        center: ['30%', '55%'],
      },
      mapper: suzukiCustomPieMapper,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericMedium',
      title: "widgets.financingReasonsVenta",
      position: [2, 21],
      size: [2, 2],
      indicator: "VENTA_SIN_FINANCIAMIENTO_MOTIVO",
      criticalMomentId: getCmID("venta"),
      extraConfigs: {
        center: ['30%', '55%'],
      },
      mapper: suzukiCustomPieMapper,
    }),
    {
      title: "widgets.otherIndicators",
      type: "label",
      position: [0, 23],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: "widgets.warrantyExplanation",
      position: [0, 24],
      size: [2, 2],
      indicator: 'VENTA_BENEFICIOS',
      mapper: (data) => {
        return data.map((item) => ({
          id: item["group"] == null ? "Indefinido" : item["group"],
          label: item["group"] == null ? "Indefinido" : item["group"],
          value: item["value"],
        }))
      },
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.entregaVenta',
      position: [2, 24],
      size: [1, 2],
      indicator: 'VENTA_ENTREGA',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.upsellingVenta',
      position: [3, 24],
      size: [1, 2],
      indicator: 'VENTA_ACCESORIOS_ADICIONALES',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.instalationsVenta',
      position: [0, 26],
      size: [1, 2],
      indicator: 'VENTA_INSTALACIONES',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.confirmacionEntregaVenta',
      position: [1, 26],
      size: [1, 2],
      indicator: 'VENTA_CONFIRMACION_ENTREGA',
      criticalMomentId: getCmID("venta"),
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByQuestion',
      title: 'Peso de atributos sobre Satisfacción',
      position: [2, 26],
      size: [2, 2],
      indicator: 'ATRIBUTOS_SATISFACCION_VENTA',
      criticalMomentId: getCmID("venta"),
      extraConfigs: {
        wrapTexts: true,
        rotateXAxisLegend: true
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByMonthRegression',
      title: 'Proyección - Satisfacción',
      position: [0, 28],
      size: [4, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      mapper: (data: any) => {
        return data &&
          data[0] &&
          data[0].source &&
          data[0].source.map((item: number, idx: any) => ({
            group: item['groupName'],
            label: item['groupName'],
            value: data[0].source[idx].value * 10,
          }))
      },
      extras: {
        aggregation: 'linearRegression'
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 30],
      size: [4, 2],
      criticalMomentId: getCmID("venta"),
    }),
  ],
} as DasboardConfig
