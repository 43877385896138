import { answeredMapper, invalidMapper } from "../dashHelper"
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, getCmID } from "./commonWidgetsJac"

export default {
  title: "Demo Post postventa Dashboard",
  description: "Demo Post postventa Dashboard",
  backgroundClassName: (filters: any) => {
    return 'epsilon-hero-bg'
  },
  widgets:(filters: FiltersUI) =>  [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'postventa_nps',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    //1
    {
      title: 'Encuestas',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'Alertas',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByMonth',
      subTitle: 'Respuesta alertas mensual',
      position: [2, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByWeek',
      subTitle: 'Respuesta alertas semanal',
      position: [3, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      position: [0, 6],
      size: [2, 2],
      indicator: 'postventa_nps',
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: [2, 6],
      size: [1, 2],
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      indicator: 'postventa-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [3, 6],
      size: [1, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label: 'widgets.indicatorId.' + item.groupId
          }
        })
      },
      indicator: 'POSTVENTA_CUSTOMER_JOURNEY',
      position: [0, 8],
      size: [4, 2],
      extraConfigs: {
        chartRadius: '70%',
        groupNameFormat: 'description'
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'Lo que hacemos mejor',
      position: [0, 10],
      size: [2, 2],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID('servicio')],
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'Lo que tenemos que mejorar',
      position: [2, 10],
      size: [2, 2],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID('servicio')],
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de estaciones',
      position: [0, 12],
      size: [2, 2],
      indicator: 'postventa_nps',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      position: [2, 12],
      size: [1, 2],
      /*extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      */
      indicator: 'postventa_satisfaccion',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [3, 12],
      size: [1, 2],
     /*
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      */
      indicator: 'postventa_satisfaccion',
      criticalMomentId: 2,
    }),
    
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsPostventa',
      position: [0, 14],
      size: [2, 1],
      indicator: 'postventa_nps',
      criticalMomentId: 1,
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsPost postventa',
      position: [0, 15],
      size: [2, 1],
      indicator: 'postventa_nps',
      criticalMomentId: 1,
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [2, 14],
      size: [2, 2],
      criticalMomentInclude: [getCmID('servicio')],
    }),

    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: [0, 16],
      size: [4, 3],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID('servicio')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'Diagrama Ishikawa',
      position: [0, 19],
      size: [4, 2],
      indicator: 'SENTIMENT',
      criticalMomentInclude: [getCmID('servicio')],
    })
  ],
} as DasboardConfig
