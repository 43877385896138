import parseActionPlanFilters from '@utils/parseActionPlanFilters'
import parseFilters from '@utils/parseFilters'
import { ApisauceInstance } from 'apisauce'
import _ from 'lodash'

/**
 * Expose all endponts for 'Alerts'.
 */
export default <T>(api: ApisauceInstance): ApiObject => ({
  //...RestApiBuilder<T>('action-plans', api),
  /* Action Plan */
  create: (data: ActionPlan) => {
    return api.post('action-plans', data)
  },
  detail: (geoLocationId, actionPlanStatus) => {
    const params = {
      geoLocationId: geoLocationId,
      library: false,
      actionPlanStatus: actionPlanStatus
    }
    return api.get('action-plans', params)
  },
  library: (segment, tag) => {
    const params = {
      segment: segment,
      tag: tag,
      library: true
    }
    return api.get('action-plans', params)
  },
  dashboard: (filters: ActionPlanFilters) => {
    const params = {
      ..._.pick(parseActionPlanFilters(filters), ['geoLocationInclude']),
      status: filters.actionPlanStatus,
      library: false
    }
    return api.get('action-plans/dashboard', params)
  },
  delete: (id) => api.delete(`action-plans/${id}`, {}),
  update: (id, data) => {
    const body = {
      actionPlanStatus: data.status
    }
    return api.put(`action-plans/${id}`, body)
  },
  updateLibrary: (id, data) => {
    const body = {
      library: data.status
    }
    return api.put(`action-plans/${id}/library`, body)
  },
  getLogs: (actionPlanId) => api.get(`/action-plans/${actionPlanId}/logs`, {}),
  downloadReport: (actionPlanId: number) => {
    return api.get(`action-plans/${actionPlanId}/comments-report`, {})
  },

  /* Reasons */
  getCauses: (actionPlanId) => {
    console.log('APY Yendo a buscar causas!!!')
    return api.get(`/action-plans/${actionPlanId}/causes`, {})
  },
  updateCauses: (actionPlanId, body) => {
    console.log('APY Yendo a grabar causas!!!')
    console.log(body)
    return api.put(`/action-plans/${actionPlanId}/causes`, body)
  },

  /* Etiquetas */
  worstTags: (geographicDistributions: number[], logicalDistributions: number[]) => {
    const params = {
      /*logicalLocationInclude: logicalDistributions
        ? logicalDistributions
        : undefined,*/
      geoLocationInclude: geographicDistributions
        ? geographicDistributions
        : [],
      groupBy: "tag",
      sentiment: -1,
      sort: "desc",
      limit: 3,
      dateRange: "actionPlanHistory"
    }
    return api.post('indicators/sentiment_tags/values', params)
  },
  getTagComments: (geographicDistributions: number[], logicalDistributions: number[], tag: string) => {
    const params = {
      logicalLocationInclude: logicalDistributions
        ? logicalDistributions
        : undefined,
      geoLocationInclude: geographicDistributions
        ? geographicDistributions
        : [],
      tag: tag,
      sentiment: -1,
      dateRange: "history"
    }
    return api.post('indicators/SENTIMENT_COMMENTS/values', params)
  },
  /* Activity */
  createActivity: (actionPlanId: number, body: any) =>
    api.post(`action-plan-activities`, { ...body, actionPlanId: actionPlanId }),
  updateActivity: (activityId: number, body: any) => {
    return api.put(`action-plan-activities/${activityId}`, body)
  },
  updateActivityProgress: (
    actionPlanId: number,
    activityId: number,
    progress: number
  ) => {
    return api.put(`/action-plans/${actionPlanId}/activities/${activityId}/progress`, {
      progress: progress,
    })
  },
  /* Activity Comments */
  getActivityComments: (activityId: number) => {
    return api.get(`action-plan-activities/${activityId}/comments`, {})
  },
  addActivityComment: (activityId: number, comment: string) => {
    const body = {
      actionPlanActivityId: activityId,
      text: comment
    }
    return api.post('action-plan-activity-comments', body)
  },
  deleteActivityComment: (activityCommentId: number) => {
    return api.delete(`action-plan-activity-comments/${activityCommentId}`, {})
  },

  /* Activity Evidences */
  addActivityEvidence: (activityId: number, data: any[]) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    const formData = new FormData()
    data.forEach((file) => {
      const blob = new Blob([file.originFileObj], { type: file.type })
      formData.append('files', blob, file.name)
    })
    return api.post(`action-plan-activities/${activityId}/evidences`, formData, config)
  },
  getActivityEvidences: (activityId: number) => {
    console.log('API buscando activity evidences!!!')
    return api.get(`action-plan-activities/${activityId}/evidences`, {})
  },
  deleteActivityEvidence: (activityEvidenceId: number) => {
    return api.delete(`action-plan-activity-evidences/${activityEvidenceId}`, {})
  },
  downloadActivityEvidence: (activityEvidenceId: number) => {
    return api.get(`action-plan-activity-evidences/${activityEvidenceId}`, {})
  },
  /* Others */
  templates: (indicatorId: number) =>
    api.get('action-plan-templates', { indicatorId }),
})
