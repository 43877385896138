import cxoResume from './cxoResume'
import nacionalServicio from './nacionalServicio'
import nacionalVenta from './nacionalVenta'
import agencia from './agencia'
//import nacionalPosventa from './nacionalPosventa'
//import nacionalVenta from './nacionalVenta'

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  widgets: (filters: FiltersUI) => [

    ...cxoResume.widgets(filters).map((wdg: any) => ({
      ...wdg,
      render: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
      },

    })),
    ...agencia.widgets(filters).map((wdg: any) => ({
      ...wdg,
      render: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(3)
      },

    })),
    ...nacionalVenta.widgets(filters).map((wdg: any) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(1),
    })),
    ...nacionalServicio.widgets(filters).map((wdg: any) => ({
      ...wdg,
      render: (filters: FiltersUI) =>
        filters !== undefined &&
        filters.criticalMoments !== undefined &&
        filters.criticalMoments !== null &&
        filters.criticalMoments.includes(2),
    })),
  ],
} as DasboardConfig
