import { ColumnsType } from 'antd/lib/table'
import {
  ClientDashboardConfig,
  DASHBOARDS,
  DashboardList,
} from '../dashboard/dashboardCommons'
import { OmoikaneConfig } from '../omoikane/omoikaneCommons'

export enum FILTER_TYPES {
  DATES = 'dates',
  LOGICALS = 'logicalDistributions',
  GEOS = 'geographicDistributions',
  CRITICAL_MOMENTS = 'criticalMoments',
  CLUSTERS = 'clusters',
  DYNAMIC = 'dynamic',
}

export enum METADADATA_LOAD {
  LOGICALS = 'logicalDistributions',
  GEOS = 'geographicDistributions',
  CRITICAL_MOMENTS = 'criticalMoments',
  CLUSTERS = 'clusters',
  DYNAMIC_REPORT_FIELDS = 'dynamicReportsFields',
  WORKFLOWS = 'workflows',
}

export interface defaultRatingRange {
  defaultRatingMin: number
  defaultRatingMax: number
}

export enum SurveyResponsesTab {
  ANSWERED = 'ANSWERED',
  NOT_ANSWERED = 'NOT_ANSWERED',
  INVALID = 'INVALID'
}

export interface ClientConfigInfo {
  // General
  vocBackendUrl: string
  lcmBackendUrl: string
  publicUrl: string | undefined
  logoSmall: any
  logo: any
  supportsInternationalization: boolean
  decimals: number | undefined
  // Filters
  filtersFromDateSubstract: number
  filtersToDateSubstract: number
  filters: FILTER_TYPES[]
  // Dashboard
  dashboards: ClientDashboardConfig
  menuDashboards: DASHBOARDS[]
  useBackDashboards: boolean
  staticDashboards: DashboardList
  sentimentCommentsIndicatorName: string
  // Metadata
  metadata: METADADATA_LOAD[]
  criticalMomentsRootId: number
  criticalMomentFilterType: 'select' | 'tree'
  geosRootId: number
  logicalsRootId: number
  getFilterCriticalMoments: (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ) => CriticalMoment[]
  getFilterCriticalMomentDefault: (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ) => number
  // Reports
  msExcelReportExt: string
  // Omoikane
  omoikane: OmoikaneConfig
  defaultRatingRange?: defaultRatingRange
  // Action Plan
  geoLeafLevel: string
  // Survey Responses
  surveyResponsesUrl: string
  surveyResponsesTableColumns: ColumnsType<any> | undefined
  surveyResponsesTabs: SurveyResponsesTab[]
}
