
import logoProfuturo from '@assets/logos/profuturo-logo.png'
import logoProfuturoSmall from '@assets/logos/profuturo-logo-small.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import { profuturoStaticDashboards } from '../dashboard/profuturo/staticDashboards'

export const profuturoConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://profuturo-backend-voc.limetropy.com/api/',
    lcmBackendUrl: 'https://profuturo-backend-core.limetropy.com/backend/core/api/ui/',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: '/voc',
    logoSmall: logoProfuturoSmall,
    logo: logoProfuturo,
    filtersFromDateSubstract: 1,
    filtersToDateSubstract: 0,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.CRITICAL_MOMENTS, FILTER_TYPES.DYNAMIC],
    supportsInternationalization: false,
    msExcelReportExt: 'xlsx',
    decimals: 0,
    menuDashboards: [DASHBOARDS.DEFAULT],
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    geosRootId: 1,
    logicalsRootId: 0,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    geoLeafLevel: '',
    omoikane: {
        decimals: 2,
        enabledTabs: [],
        monthdsCoverage: 2,
        indicators: [],
        sentiment: {
            indicators: {},
            properties: {
                sentiment: '',
                percentage: '',
                sentimentLocal: '',
                percentageLocal: ''     
            }
        },
        customerJourney: {},
        cxRay: { indicators: {} },
        clusters: {},
    },
    useBackDashboards: true,
    metadata: [
        METADADATA_LOAD.CRITICAL_MOMENTS,
        //METADADATA_LOAD.WORKFLOWS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.profuturo,
        default: DASHBOARDS_CONFIGS.profuturo[DASHBOARDS.DEFAULT]
    },
    staticDashboards: profuturoStaticDashboards,
    surveyResponsesUrl: 'survey-responses',
    surveyResponsesTableColumns: undefined,
    surveyResponsesTabs: [SurveyResponsesTab.ANSWERED, SurveyResponsesTab.NOT_ANSWERED]
}
