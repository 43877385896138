import { filterGeos } from '@utils/GeoUtils'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'
import { pieColors } from '../dashHelper'

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  surveyCountMonthTrend: {
    subTitle: '# de Encuestas respondidas por mes',
    helpLink: '--',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'number-surveys-month-trend',
      type: 'trend',
      colors: '#a7eb12',
      showHistoric: false,
      percentageScale: false,
      showSample: true,
      backgroundColor: '#5F2B87',
      sampleColor: '#5F2F82',
      fontColor: 'white',
      mapper: (data, extra) => {
        const selectedGeos = filterGeos(
          extra['geoLocations'],
          extra['geoLocationsInclude']
        ).length

        return data.map((item: any, idx: any) => ({
          ...item,
          id: idx,
          value:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount']
              : 0,
          trend:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount'] / selectedGeos
              : 0,
          count:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount']
              : 0,
        }))
      },
      indicators: [
        {
          indicator: 'SURVEY_COUNT',
          grouped: 'formattedMonth',
          keyExtract: ['group', 'value', 'groupName', 'count'],
          label: 'group',
          value: 'value',
          dataType: 'list',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {},
        },
      ],
    },
  },
  surveyResponsesTrend: {
    title: 'Encuestas registradas',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'survey-responses-trend',
      type: 'trend',
      colors: '#a7eb12',
      backgroundColor: '#5F2B87',
      sampleColor: '#5F2F82',
      fontColor: 'white',
      valueSymbol: undefined,
      showHistoric: false,
      showSample: true,
      showTrend: false,
      decimals: 0,
      multiIndicator: true,
      mapper: (data: any) => {
        //console.log('Survey responses trend mapper')
        //console.log(data)

        const finalData = data && data[0] && data[0].resume ? data[0].resume : undefined

        if (finalData === undefined) {
          return []
        }

        const types = [
          //'answered',
          'notifications',
          //'queued',
          //'processed',
          'sent',
          'error',
          'opened',
          'clicked',
          'answered'
        ]

        const typeNames = {
          answered: 'Respondida',
          notifications: 'Registrada',
          queued: 'Encolada',
          processed: 'Procesada',
          sent: 'Enviada',
          error: 'Con error',
          clicked: 'Clickeada',
          opened: 'Abierta'
        }

        return types.map((type: string, idx: number) => ({
          id: idx,
          groupName: typeNames[type],
          value: finalData[type] ?? 0,
          count: finalData[type] ?? 0
        })).reverse()
      },
      indicators: [
        {
          indicator: 'survey-responses-trend',
          url: 'dashboard/resume',
          //grouped: 'formattedMonth',
          keyExtract: ['resume'],
          value: 'value',
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {
            useLcmApi: true
          },
        }
      ],
    },
  },
  textAnalysis: {
    title: 'Análisis de sentimientos',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'text-analysis-qr',
      type: 'textAnalysis',
      mapper: (data: any): any => {
        const newData = data !== undefined
          ? {
            ...data,
            categories: data.categories
              .filter((cat: any) => cat.group !== 'DEFAULT')
              .map((cat: any) => ({
                ...cat,
                tags: cat.tags.filter((cat: any) => cat.group !== 'DEFAULT')
              }))

          }
          : {}
        return newData
      },
      indicators: [
        {
          indicator: 'SENTIMENT',
          grouped: 'tag',
          keyExtract: ['categories', 'count'],
          singleton: false,
          value: 'value',
          extras: {},
        },
      ],
    },
  },
  customLineDetailedByMonthAndGeo: {
    title: 'Satisfacción General',
    // [Column, Row]
    position: [0, 0],
    size: [4, 3],
    config: {
      id: 'line-detailed-by-month-and-geo',
      type: 'lineDetailed',
      //fillMode: 'gradient',
      fillMode: '',
      colors: pieColors,
      showValues: false,
      //percentageScale: false,
      shadow: false,
      mapper: (data: any) => {
        //console.log('Rendering line-detailed-by-month-and-geo')
        //console.log(data)
        const metrics = [
          { label: 'NPS General', value: data['nps-gral'] },
          ...data['nps-by-critical-moment'].map((item: any) => ({
            label: item.group,
            value: item.value
          })),
          ...data['nps-by-geo'].map((item: any) => ({
            label: item.group,
            value: item.value
          }))
        ]

        return {
          value: data['nps-by-month'].map((item: any, idx: number) => ({
            ...item,
            id: idx,
            category: item.groupName ? item.groupName : item['group_0'],
            group: item['group_1'] ? item['group_1'] : item.label,
          })),
          metrics: metrics
        }
      },
      indicators: [
        {
          key: 'nps-by-month',
          indicator: 'pt_danosrep_sal_pregunta_1',
          grouped: 'formattedMonth,geoLocation',
          dataType: 'single',
          keyExtract: [
            'group_0',
            'group_1',
            'groupId_0',
            'value',
            'groupName'
          ],
          label: 'group',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
        {
          key: 'nps-gral',
          indicator: 'pt_danosrep_sal_pregunta_1',
          keyExtract: ['value'],
          value: 'value',
          dataType: 'single',
          singleton: false,
          extras: {},
        },
        {
          key: 'nps-by-critical-moment',
          indicator: 'pt_danosrep_sal_pregunta_1',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'criticalMoment',
          dataType: 'single',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
        {
          key: 'nps-by-geo',
          indicator: 'pt_danosrep_sal_pregunta_1',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
      ],
    },
  },
}
