import { ReactComponent as DashboardIcon } from '../assets/svg/icon_dashboard.svg'
import { ReactComponent as SurveysIcon } from '../assets/svg/icon_surveys.svg'
import { ReactComponent as StatisticsIcon } from '../assets/svg/icon_statistics.svg'
import { ReactComponent as AlertsIcon } from '../assets/svg/icon_alerts.svg'
import { ReactComponent as ReportsIcon } from '../assets/svg/icon_reportes.svg'
import { ReactComponent as ActionPlansIcon } from '../assets/svg/icon_actionPlans.svg'
import { ReactComponent as AiIcon } from '../assets/svg/icon_ai.svg'
import React from 'react'

const icons = {
  dashboard: (
    <DashboardIcon width={20} style={{ marginRight: 8 }} fill='#7967AB' />
  ),
  surveys: <SurveysIcon width={20} style={{ marginRight: 8 }} fill='#7967AB' />,
  statistics: (
    <StatisticsIcon width={20} style={{ marginRight: 8 }} fill='#7967AB' />
  ),
  alerts: <AlertsIcon width={20} style={{ marginRight: 8 }} fill='#7967AB' />,
  reports: <ReportsIcon width={20} style={{ marginRight: 8 }} fill='#7967AB' />,
  actionPlans: (
    <ActionPlansIcon width={20} style={{ marginRight: 8 }} fill='#7967AB' />
  ),
  iaMotor: <AiIcon width={20} style={{ marginRight: 8 }} fill='#7967AB' />,
}

export default icons
