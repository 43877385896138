export default {
  title: 'Acompañamiento Dashboard',
  description: 'Acompañamiento Dashboard',
  backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    {
      title: 'NPS',
      position: [0, 0],
      size: [1, 2],
      config: {
        id: 'satisfaccion-nps',
        type: 'nps',
        color: ['#CCC'],
        enableArcLabels: true,
        valueFormat: ' >-.0%',
        indicators: [
          {
            indicator: 'AFORE_NPS',
            keyExtract: ['passives', 'detractors', 'promoters', 'value'],
            extras: {}
          }
        ]
      }
    },
    {
      title: 'Satisfacción',
      position: [1, 0],
      size: [1, 2],
      config: {
        id: 'satisfaccion',
        type: 'semicircle',
        colors: ['#669D20', '#D6EE4B', '#FFD01B', '#FF0000', '#990001'],
        valueFormat: ' >-.0%',
        enableArcLabels: true,
        useGroupValue: 1,
        indicators: [
          {
            indicator: 'cuestferiaafore_pregunta_3',
            keyExtract: ['value', 'details'],
            label: 'group',
            value: 'value',
            extras: {
              groupNameFormat: 'description',
              'model': 'avg'
            }
          }
        ]
      }
    },
    {
      title: 'Atención',
      position: [3, 0],
      size: [2, 2],
      config: {
        id: 'cuestferiaafore_pregunta_10',
        type: 'pie',
        colors: ['#015c52', '#33b9ce', '#f38625', '#669D20', '#D6EE4B', '#FFD01B', '#FF0000', '#990001'],
        'anchor': 'top-left',
        'direction': 'column',
        valueFormat: ' >-.0%',
        'legendTranslateX': -10,
        'marginLeft': 250,
        'itemDirection': 'right-to-left',
        indicators: [
          {
            indicator: 'cuestferiaafore_pregunta_10',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupNameFormat: 'description'
            }
          }
        ]
      }
    },
    {
      title: 'Asesoria',
      position: [0, 2],
      size: [1, 3],
      config: {
        id: 'asesoria',
        type: 'semicircle',
        enableArcLabels: true,
        useGroupValue: 1,
        colors: ['#669D20', '#D6EE4B', '#FFD01B', '#FF0000', '#990001'],
        valueFormat: ' >-.0%',
        indicators: [
          {
            indicator: 'cuestferiaafore_pregunta_9',
            keyExtract: ['value', 'details'],
            label: 'group',
            value: 'value',
            extras: {
              groupNameFormat: 'description',
              'model': 'avg'
            }
          }
        ]
      }
    },
    {
      title: 'La ubicación del módulo (diseño para identificarlo)',
      position: [1, 2],
      size: [3, 1],
      config: {
        id: 'cuestferiaafore_pregunta_6',
        type: 'stackedBar',
        colors: ['#669D20', '#D6EE4B', '#FFD01B', '#FF0000', '#990001'],
        fillMode: '',
        showValues: true,
        showLegend: true,
        valueFormat: ' >-.0%',
        itemWidth: 220,
        indicators: [
          {
            indicator: 'cuestferiaafore_pregunta_6',
            keyExtract: ['group', 'groupId', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupNameFormat: 'description'
            }
          }
        ]
      }
    },
    {
      title: 'La instalación (limpieza, espacio de espera, espacio de atención temperatura)',
      position: [1, 3],
      size: [3, 1],
      config: {
        id: 'cuestferiaafore_pregunta_7',
        type: 'stackedBar',
        colors: ['#669D20', '#D6EE4B', '#FFD01B', '#FF0000', '#990001'],
        fillMode: '',
        showValues: true,
        showLegend: true,
        valueFormat: ' >-.0%',
        itemWidth: 220,
        indicators: [
          {
            indicator: 'cuestferiaafore_pregunta_7',
            keyExtract: ['group', 'groupId', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupNameFormat: 'description'
            }
          }
        ]
      }
    },
    {
      title: 'Promocionales (plumas, videos, flyers)',
      position: [1, 4],
      size: [3, 1],
      config: {
        id: 'cuestferiaafore_pregunta_8',
        type: 'stackedBar',
        colors: ['#669D20', '#D6EE4B', '#FFD01B', '#FF0000', '#990001'],
        fillMode: '',
        showValues: true,
        showLegend: true,
        valueFormat: ' >-.0%',
        itemWidth: 220,
        indicators: [
          {
            indicator: 'cuestferiaafore_pregunta_8',
            keyExtract: ['group', 'groupId', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupNameFormat: 'description'
            }
          }
        ]
      }
    }
  ]
} as DasboardConfig
