import { getCommonWidget } from "../dashWidgetHelper";
import { commonWidgets } from "./commonWidgetsCaffenio";

export default {
  title: 'General Dashboard',
  description: 'General de perfil Default',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: [0, 0],
      size: [4, 4],
      criticalMomentInclude: [0, 29]
    }),
    /*getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyResponsesTrend',
      position: [0, 4],
      size: [1, 2],
      criticalMomentInclude: [0, 29]
    }),*/
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 4],
      size: [4, 2],
      criticalMomentInclude: [0, 29]
    }),
  ],
} as DasboardConfig
