import { pieColors } from '../dashHelper'


export const widgetsLine: CommonWidget = {

  /**************************************************************************
   *                                LINES
  ***************************************************************************/

  alertsLineTrendByMonthAndGeo: {
    title: 'Tendencia % EESS',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'alerts-line-trend-by-month-and-geo',
      type: 'line',
      colors: ['#269102', '#d64550'],
      radius: [40, 85],
      center: ['40%', '55%'],
      legendOrient: 'vertical',
      showHistoric: false,
      showSample: false,
      groups: [
        '% Excelente. Más de 30 encuestas',
        '% Criticas. Menos de 10 encuestas',
      ],
      wrapXAxisText: true,
      mapper: (data: any) => {
        const groupByCategory = data.reduce((all: any, item: any) => {
          // Calculos
          const excelente = item.value.answeredCount >= 30 ? 1 : 0
          const neutro =
            item.value.answeredCount >= 10 && item.value.answeredCount < 30
              ? 1
              : 0
          const critico = item.value.answeredCount < 10 ? 1 : 0

          const group = item['group_0']
          all[group] = all[group] ?? {
            excelente: 0,
            neutro: 0,
            critico: 0,
          }

          const current = {
            //...all[group],
            groupName: item.groupName,
            group: group,
            excelente: all[group].excelente + excelente,
            neutro: all[group].neutro + neutro,
            critico: all[group].critico + critico,
            total:
              all[group].excelente +
              excelente +
              all[group].neutro +
              neutro +
              all[group].critico +
              critico,
          }

          all[group] = current
          return all
        }, {})

        const result = Object.keys(groupByCategory)
          .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
          .map((key: string, idx: number) => {
            const item = groupByCategory[key]
            const groups = item.group !== undefined && item.group.split('-')
            return [
              {
                id: idx,
                group: '% Criticas. Menos de 10 encuestas',
                category:
                  item.groupName + (groups && groups[0] ? ' ' + groups[0] : ''),
                value: Math.round((100 * item.critico) / item.total),
              },
              {
                id: idx,
                group: '% Excelente. Más de 30 encuestas',
                category:
                  item.groupName + (groups && groups[0] ? ' ' + groups[0] : ''),
                value: Math.round((100 * item.excelente) / item.total),
              },
            ]
          })
          .flat(1)

        return result
      },
      indicators: [
        {
          //key: 'alert-first-response-time',
          indicator: 'survey-count',
          grouped: 'formattedMonth,geoLocation',
          keyExtract: [
            'group',
            'value',
            'groupName',
            'count',
            'group_0',
            'group_1',
          ],
          label: 'groupName',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            featureExclude: ['groupByTopLocation'],
            periods: 10
          },
        },
      ],
    },
  },
  alertsLineTrendByGeo: {
    title: '% EESS por Región',
    // [Column, Row]
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'alerts-line-trend-by-geo',
      type: 'line',
      radius: [50, 100],
      center: ['40%', '55%'],
      showHistoric: false,
      showSample: false,
      legendOrient: 'vertical',
      groups: [
        '% Excelente. Más de 30 encuestas',
        '% Criticas. Menos de 10 encuestas',
      ],
      colors: ['#269102', '#d64550'],
      wrapXAxisText: true,
      mapper: (data: any) => {
        const groupByCategory = data.reduce((all: any, item: any) => {
          // Calculos
          const excelente = item.value.answeredCount >= 30 ? 1 : 0
          const neutro =
            item.value.answeredCount >= 10 && item.value.answeredCount < 30
              ? 1
              : 0
          const critico = item.value.answeredCount < 10 ? 1 : 0

          const group = item['group']
          all[group] = all[group] ?? {
            excelente: 0,
            neutro: 0,
            critico: 0,
          }

          const current = {
            //...all[group],
            group: group,
            excelente: all[group].excelente + excelente,
            neutro: all[group].neutro + neutro,
            critico: all[group].critico + critico,
            total:
              all[group].excelente +
              excelente +
              all[group].neutro +
              neutro +
              all[group].critico +
              critico,
          }

          all[group] = current
          return all
        }, {})

        const result = Object.keys(groupByCategory)
          .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
          .map((key: string) => {
            const item = groupByCategory[key]
            return [
              {
                ...item,
                group: '% Criticas. Menos de 10 encuestas',
                category: item.group,
                value: Math.round((100 * item.critico) / item.total),
              },
              {
                ...item,
                group: '% Excelente. Más de 30 encuestas',
                category: item.group,
                value: Math.round((100 * item.excelente) / item.total),
              },
            ]
          })
          .flat(1)

        return result
      },
      indicators: [
        {
          indicator: 'survey-count',
          grouped: 'geoLocation',
          keyExtract: ['group', 'value', 'groupName', 'count', 'groupId'],
          label: 'groupName',
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            featureExclude: ['groupByTopLocation'],
            ancestorName: 0,
            periods: 10
          },
        },
      ],
    },
  },

  lineDetailedByMonthAndGeo: {
    title: 'Satisfacción General',
    // [Column, Row]
    position: [0, 0],
    size: [4, 3],
    config: {
      id: 'line-detailed-by-month-and-geo',
      type: 'lineDetailed',
      //fillMode: 'gradient',
      fillMode: '',
      colors: pieColors,
      showValues: false,
      //percentageScale: false,
      shadow: false,
      mapper: (data: any) => {
        //console.log('lineDetailedByMonthAndGeo!!!!!!!!')
        //console.log(data)
        return {
          value: data.map((item: any, idx: number) => ({
            ...item,
            id: idx,
            category: item.groupName ? item.groupName : item['group_0'],
            groupName: item['group_1'] ? item['group_1'] : item.label,
            value: item.value
          }))
        }
      },
      indicators: [
        {
          indicator: 'satisfaction',
          grouped: 'formattedMonth,geoLocation',
          keyExtract: [
            'group_0',
            'group_1',
            'groupId_0',
            'value',
            'groupName'
          ],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            periods: 6
          },
        },
      ],
    },
  },
}
