import React from 'react'
import { Badge } from "antd"
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import useClientTranslation from 'config/clients/useClientTranslation'

export const iamsaSurveyResponsesTableColumns = [
  {
    id: 1,
    dataIndex: 'survey',
    key: 'survey',
    title: 'Nombre',
    width: 130,
    //enableSearch: true,
    render: (value: any, record: any) => {
      if (record.status === 'ANSWERED') {
        const customer =
          (record?.customerName ?? '') +
          ' ' +
          (record?.customerLastname ?? '').trim()

        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontWeight: 800, color: '#5A5A5A' }}>
              {customer}
            </div>
            <div style={{ color: '#5A5A5A' }}>
              {record?.geoLocationDescription}
            </div>
          </div>
        )
      }

      return (
        <div style={{ color: '#5A5A5A' }}>
          {record.customerCode}
        </div>
      )

    },
  },
  {
    id: 2,
    dataIndex: 'fFechaViaje',
    key: 'fFechaViaje',
    title: 'Fecha de viaje',
    width: 70,
    render: (value: any, record: any) => {
      return record.surveyResponseContext.surveyOperation.fFechaViaje
    },
  },
  {
    id: 3,
    dataIndex: 'sOrigen',
    key: 'sOrigen',
    title: 'Origen',
    width: 70,
    render: (value: any, record: any) => {
      return record.surveyResponseContext.surveyOperation.sOrigen
    },
  },
  {
    id: 4,
    dataIndex: 'sDestino',
    key: 'sDestino',
    title: 'Destino',
    width: 70,
    render: (value: any, record: any) => {
      return record.surveyResponseContext.surveyOperation.sDestino
    },
  },
  {
    id: 5,
    dataIndex: 'nAutobus',
    key: 'nAutobus',
    title: 'Nro. Autobus',
    width: 70,
    render: (value: any, record: any) => {
      return record.surveyResponseContext.surveyOperation.nAutobus
    },
  },
  {
    id: 6,
    dataIndex: 'sNomOperador1',
    key: 'sNomOperador1',
    title: 'Conductor',
    width: 100,
    render: (value: any, record: any) => {
      return record.surveyResponseContext.surveyOperation.sNomOperador1
    },
  },
  {
    id: 7,
    dataIndex: 'commentAnswer',
    key: 'commentAnswer',
    title: 'Comentario',
    width: 160,
    render: (value: any, record: any) => {
      const ca = record.questionResponses
        .find((q: any) => q.questionType === 'COMMENT')

      return ca !== undefined ? ca.commentAnswer : '-'
    },
  },/*
  {
    id: 8,
    dataIndex: 'surveyCode',
    key: 'surveyCode',
    title: 'Código encuesta',
    width: 100,
    className: 'actions-style-status-alerts',
    render: (value: any, record: any) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ color: '#383838' }}>{record.surveyCode || record.customerServiceCode || '-'}</div>
        </div>
      )
    },
  },
  {
    id: 9,
    dataIndex: 'surveyStatus',
    key: 'surveyStatus',
    title: 'Estado',
    width: 100,
    className: 'actions-style-status-alerts',
    render: (value: any, record: any) => {
      return <Badge
        style={{
          backgroundColor:
            record.status === 'ANSWERED' ? 'green' : 'red',
        }}
        className={`badge-${record.status.toLowerCase()}`}
      >
        {useClientTranslation().translate('surveys.' + record.status)}
      </Badge>
    },
  },*/
  {
    id: 10,
    dataIndex: 'createdAt',
    key: 'createdAt',
    title: 'Fecha Respuesta',
    width: 80,
    className: 'actions-style-status-alerts',
    render: (value: any, record: any) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/*<div style={{ color: "#959595" }}>{record?.clientId}</div>*/}
          <div style={{ color: '#383838' }}>
            {dayjs(value).format('DD/MM/YYYY')}
          </div>
        </div>
      )
    },
  },
  {
    id: 11,
    title: '',
    width: 90,
    dataIndex: 'id',
    key: 'detail-button',
    className: 'actions',
    onCell: (record: Record<string, any>) => ({
      onClick: () => { },
    }),
    render: (val: string, record: any) => (
      <Link
        key={`action-${record.id}`}
        to={`/survey-stats/detail/${record.id}`}
        style={{
          backgroundColor: '#F9F9F9',
          border: '1px solid #E9E9E9',
          borderRadius: 50,
          color: '#555555',
          fontWeight: 500,
          textDecoration: 'none',
          maxWidth: '90px'
        }}
      >
        Detalles
      </Link>
    ),
  },
]