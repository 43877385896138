import parseFilters from '@utils/parseFilters'
import parseFiltersIndicators from '@utils/parseFiltersIndicators'
import RestApiBuilder from '@utils/RestApiBuilder'
import { ApisauceInstance } from 'apisauce'
import useClientConfig from 'config/clients/useClientConfig'

/**
 * Expose all endponts for 'reports'.
 */
export default <T>(vocApi: ApisauceInstance, lcmApi: ApisauceInstance): ApiObject => {
  const { getSurveyResponsesUrl} = useClientConfig()
  return {
    ...RestApiBuilder<T>(getSurveyResponsesUrl(), vocApi),
    vocCounters: (filters: FiltersUI) => vocApi.post('survey-responses/counters', {
      ...( filters && parseFiltersIndicators(filters)),
    }),
    lcmCounters: (filters: FiltersUI) => lcmApi.get('dashboard/resume', {
      ...( filters && parseFilters(filters)),
    }),
  }
}
