import Immutable from 'seamless-immutable'
import { pieColors } from '../dashHelper'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'
import { getLeavesByField } from '@utils/GeoUtils'
import { COLORS_CONFIG } from '@components/widgets/apacheEchart/Commons'

const QUARTER_KEYS = {
  'durante_a_pregunta_13': 'Alimentos',
  'durante_a_pregunta_33': 'Trato',
  'durante_a_pregunta_11': 'Atención telefónica',
  'durante_a_pregunta_24': 'Trato enferemeria',
  'durante_a_pregunta_8': 'Instalaciones',
}

export const haTrendDataMapper = (data: any, extra?: any) => {
  if (data === undefined || data.length === 0) {
    return data
  }

  const geoLeaves: string[] = getLeavesByField(extra.geoLocations[0], 'name')

  const root = data.filter((item: any) => item.group === null)

  const regions = Immutable.asMutable(data)
    .filter((item: any) => item.group !== null && !geoLeaves.includes(item.group))
    .sort((item1: any, item2: any) => item2.value - item1.value)

  const leaves = Immutable.asMutable(data)
    .filter((item: any) => geoLeaves.includes(item.group))
    .sort((item1: any, item2: any) => item2.value - item1.value)

  const finalData = [
    ...root,
    ...regions,
    ...leaves
  ].slice(0, 5)

  return finalData.reverse()
}

export const yesNoMapperHa = (data: any) => {
  if (data === undefined || data.length === 0) {
    return data
  }

  const finalData = data
    .map((item: any) => ({
      ...item,
      groupName: item.group === '0' ? 'Si' : 'No',
      label: item.group === '0' ? 'Si' : 'No'
    }))
    //@ts-ignore
    .asMutable()
    //@ts-ignore
    .sort((item1, item2) => item1.group === '0' ? -1 : 1)

  return finalData
}

export const haLineDetailedByMonthMapper = (data: any, extra: any) => {
  console.log('haLineDetailedByMonthMapper')
  console.log(data)
  const metrics = [
    { label: 'General', value: data['data-gral'] },
    ...data['grouped-by-critical-moment'].map((item: any) => ({
      label: item.group,
      value: item.value
    })),
    ...data['grouped-by-geo'].map((item: any) => ({
      label: item.group,
      value: item.value
    }))
  ]

  return {
    value: data['grouped-by-month'].map((item: any, idx: number) => ({
      ...item,
      id: idx,
      //category: item.groupName ? item.groupName : item['group_0'],
      //group: item['group_1'] ? item['group_1'] : item.label,
      category: item.groupName ? item.groupName : item['group_0'],
      groupName: item['group_1'] ? item['group_1'] : item.label,
    })),
    metrics: metrics
  }
}

export const haLineDetailedByGeoMapper = (data: any, extra: any) => {
  const metrics = [
    { label: 'General', value: data['data-gral'] },
    ...data['grouped-by-critical-moment'].map((item: any) => ({
      label: item.group,
      value: item.value
    })),
    ...data['grouped-by-geo'].map((item: any) => ({
      label: item.group,
      value: item.value
    }))
  ]

  return {
    value: data['grouped-by-month'].map((item: any, idx: number) => ({
      ...item,
      id: idx,
      //category: item.groupName ? item.groupName : item['group_0'],
      //group: item['group_1'] ? item['group_1'] : item.label,
      category: item.groupName ? item.groupName : item['group_0'],
      groupName: item['group_1'] ? item['group_1'] : item.label,
    })),
    metrics: metrics
  }
}

export const haBarDetailedByMonthMapper = (data: any, extra: any) => {
  //console.log('Rendering bar-detailed-by-geo')
  //console.log(data)
  const metrics = [
    { label: 'General', value: data['data-gral'] },
    ...data['grouped-by-critical-moment'].map((item: any) => ({
      label: item.group,
      value: item.value
    }))
  ]

  return {
    value: data['grouped-by-geo'].map((item: any, idx: number) => {
      var groupName = item.groupName ? item.groupName : item.group
      groupName = groupName.replaceAll('Hospital ', '')
      return {
        ...item,
        id: idx,
        value: item.value,
        group: groupName,
        label: groupName
      }
    }),
    metrics: metrics
  }
}

export const haLeafLineDetailedByGeoMapper = (data: any) => {
  console.log('haLeafLineDetailedByGeoMapper!!!!!!!!')
  console.log(data)
  const metrics = [
    { label: 'General', value: data['data-gral'] },
    ...data['grouped-by-geo'].map((item: any) => ({
      label: item.group,
      value: item.value
    })),
    ...data['grouped-by-geo-leaf'].map((item: any) => ({
      label: item.group,
      value: item.value
    }))
  ]

  return {
    value: data['grouped-by-month-and-geo'].map((item: any, idx: number) => ({
      ...item,
      id: idx,
      //category: item.groupName ? item.groupName : item['group_0'],
      //group: item['group_1'] ? item['group_1'] : item.label,
      category: item.groupName ? item.groupName : item['group_0'],
      groupName: item['group_1'] ? item['group_1'] : item.label,
    })),
    metrics: metrics
  }
}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  haLineDetailedByMonthAndGeo: {
    title: 'Satisfacción General',
    // [Column, Row]
    position: [0, 0],
    size: [4, 3],
    config: {
      id: 'line-detailed-by-month-and-geo',
      type: 'lineDetailed',
      //fillMode: 'gradient',
      fillMode: '',
      colors: pieColors,
      showValues: false,
      //percentageScale: false,
      shadow: false,
      indicators: [
        {
          key: 'grouped-by-month',
          indicator: 'FALTA',
          grouped: 'formattedMonth,geoLocation',
          dataType: 'single',
          keyExtract: [
            'group_0',
            'group_1',
            'groupId_0',
            'value',
            'groupName'
          ],
          label: 'group',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
        {
          key: 'data-gral',
          indicator: 'FALTA',
          keyExtract: ['value'],
          value: 'value',
          dataType: 'single',
          singleton: false,
          extras: {},
        },
        {
          key: 'grouped-by-critical-moment',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'criticalMoment',
          dataType: 'single',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
        {
          key: 'grouped-by-geo',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
      ],
    },
  },
  haBarDetailedByMonthAndGeo: {
    title: 'Satisfacción General',
    // [Column, Row]
    position: [0, 0],
    size: [4, 3],
    config: {
      id: 'bar-detailed-by-geo',
      type: 'barDetailed',
      //fillMode: 'gradient',
      fillMode: '',
      colors: COLORS_CONFIG,
      showValues: false,
      wrapTexts: true,
      //percentageScale: false,
      shadow: false,
      rotateXAxisLegend: true,
      indicators: [
        {
          key: 'grouped-by-geo',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
            groupByLevel: 2
          },
        },
        {
          key: 'data-gral',
          indicator: 'FALTA',
          keyExtract: ['value'],
          value: 'value',
          dataType: 'single',
          singleton: false,
          extras: {},
        },
        {
          key: 'grouped-by-critical-moment',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'criticalMoment',
          dataType: 'single',
          singleton: true,
          extras: {
            periods: 6,
            groupNameFormat: 'description'
          },
        },
      ],
    },
  },
  hsLeafLineDetailedByMonthAndGeo: {
    ...widgetsLibrary.lineDetailedByMonthAndGeo,
    //@ts-ignore
    config: {
      ...widgetsLibrary.lineDetailedByMonthAndGeo.config,
      decimals: 0,
      indicators: [
        {
          key: 'grouped-by-month-and-geo',
          indicator: 'FALTA',
          grouped: 'formattedMonth,geoLocation',
          keyExtract: [
            'group_0',
            'group_1',
            'groupId_0',
            'value',
            'groupName'
          ],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            periods: 6
          },
        },
        {
          key: 'data-gral',
          indicator: 'FALTA',
          keyExtract: ['value'],
          value: 'value',
          dataType: 'single',
          singleton: false,
          extras: {},
        },
        {
          key: 'grouped-by-geo',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
            groupByLevel: 1
          },
        },
        {
          key: 'grouped-by-geo-leaf',
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'groupName'],
          grouped: 'geoLocation',
          dataType: 'single',
          singleton: true,
          extras: {
            groupNameFormat: 'description'
          },
        }
      ],
    }
  },
  haTrendByGeo: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'generic-trend-by-geo',
      type: 'trend',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: true,
      showTrend: false,
      decimals: 2,
      multiIndicator: true,
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'geoLocation',
          keyExtract: ['value', 'groupName', 'count', 'group'],
          value: 'value',
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {
            featureInclude: ['zoomOut'],
            //featureInclude: ['zoomOut', 'groupBySelfInclude'],
            //maxGroupByLevel: 2
          },
        }
      ],
    },
  },
  quarterTrend: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'quarter-trend',
      type: 'quarter',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: false,
      showTrend: false,
      decimals: 2,
      axisYTitleMarginTop: '100%',
      cellType: 'bubble',
      categories: ['¿Cómo lo hacemos?', 'Impacto en la satisfacción del cliente'],
      mapper: (data: any) => {

        const correlaciones = data['correlaciones']
        const valores = data['valores']

        var legends = {}
        var tooltips = {}
        var finalData = {}
        correlaciones.map((correlacion: any, idx: number) => {
          const groupName = correlacion.groupName
          const key = correlacion.group
          var valueY = correlacion['value_norm']
          var valueX = valores.find((item: any) => item.groupId === key)?.value

          const legendSplit = correlacion.groupName.split('_')

          tooltips[groupName] = correlacion.description
          legends[groupName] = [QUARTER_KEYS[groupName], valueY && (valueY.toFixed(1) + '%')]

          valueY = valueY < 70 ? 1 : (valueY > 90 ? 3 : 2)
          valueX = valueX < 70 ? 1 : (valueX > 90 ? 3 : 2)
          finalData[groupName] = [valueX, valueY]
        })

        return {
          values: finalData,
          tooltips: tooltips,
          legends: legends
        }
      },
      indicators: [
        {
          indicator: 'CORRELATION',
          key: 'correlaciones',
          dataType: 'list',
          keyExtract: [
            'groupName', 'count', 'description',
            'value', 'group', 'value_norm'
          ],
          value: 'value',
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {
            correlationTarget: 10879,
            sort: "asc",
            normalize: true,
            normalize_a: "25",
            normalize_b: "75"
          },
        },
        {
          indicator: 'CORRELATION_VALUES',
          key: 'valores',
          grouped: 'question',
          dataType: 'list',
          keyExtract: [
            'fanCount', 'detractorCount',
            'count', 'passiveCount',
            'promoterCount', 'group',
            'value', 'promoters', 'detractors',
            'fans', 'passives', 'promoterNoFanCount',
            'promotersNoFan', 'groupId'
          ],
          value: 'value',
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {},
        }
      ],
    },
  },
}

