import { Icon } from '@ant-design/compatible'
import { CaretDownOutlined } from '@ant-design/icons'
import useConnection from '@hooks/useConnection'
import { Dropdown, Menu, Typography } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useClientTranslation from 'config/clients/useClientTranslation'
import { ReactComponent as ReportsIcon } from '../../assets/svg/icon_reportes.svg'

import './EpsilonMenuDropdown.less'
import icons from '@utils/icons'

const EpsilonMenuDropdown: React.FC = () => {
  const { translate } = useClientTranslation()
  const [selected, setSelected] = useState<MenuType | undefined>(undefined)

  const { menuItems } = useConnection<{
    menuItems: Array<MenuType>
  }>(['appData.menuItems'])

  //console.log('Rendering dropdown menu')
  //console.log(menuItems)

  const firstObjectMenu = menuItems[0]

  const menu = () => {
    return (
      <Menu className='menu'>
        {menuItems.map((item) => (
          <Menu.Item
            key={item.path}
            onClick={() => setSelected(item)}
            className='menu-item'
          >
            <Link className='menu-link' to={item.path}>
              {typeof item.icon === 'string' ? (
                <Icon
                  theme='outlined'
                  type={translate(item.icon)}
                  style={{ fontSize: 20, color: '#7967AB' }}
                />
              ) : (
                item.icon
              )}

              <span>{translate(item.label)}</span>
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  return (
    <Dropdown
      overlay={menu}
      className='epsilon-menu-dropdown'
      trigger={['click']}
    >
      <a onClick={(e) => e.preventDefault()}>
        {selected !== undefined ? (
          <div className='epsilon-menu-dropdown-select'>
            {typeof selected.icon === 'string' ? (
              <Icon
                theme='outlined'
                type={translate(selected.icon)}
                style={{ fontSize: 20, color: '#7967AB' }}
              />
            ) : (
              selected.icon
            )}

            <div className='epsilon-menu-focus-styled'>
              <Typography className='general-title-menu-dropdown'>
                {translate('menu.menu')}
              </Typography>
              <Typography className='general-text-menu-dropdown'>
                {translate(selected.label)}
              </Typography>
            </div>
            <CaretDownOutlined style={{ color: '#7858CD' }} />
          </div>
        ) : firstObjectMenu !== undefined ? (
          <div className='epsilon-menu-dropdown-select'>
            {typeof firstObjectMenu.icon === 'string' ? (
              <Icon
                theme='outlined'
                type={translate(firstObjectMenu.icon)}
                style={{ fontSize: 20, color: '#7967AB' }}
              />
            ) : (
              firstObjectMenu.icon
            )}

            <div className='epsilon-menu-focus-styled'>
              <Typography className='general-title-menu-dropdown'>
                {translate('menu.menu')}
              </Typography>
              <Typography className='general-text-menu-dropdown'>
                {translate(firstObjectMenu.label)}
              </Typography>
            </div>
            <CaretDownOutlined style={{ color: '#7858CD' }} />
          </div>
        ) : (
          <div></div>
        )}
      </a>
    </Dropdown>
  )
}

export default EpsilonMenuDropdown
