import React from 'react'
import { Badge } from "antd"
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import ImmutableTransformer from '@utils/ImmutableTransformer'
import useClientTranslation from 'config/clients/useClientTranslation'

export const haSurveyResponsesTableColumns = [
    {
        id: 1,
        dataIndex: 'survey',
        key: 'survey',
        title: 'Nombre',
        width: 150,
        //enableSearch: true,
        render: (value: any, record: any) => {
            if (record.status === 'ANSWERED') {
                const customer =
                    (record?.customerName ?? '') +
                    ' ' +
                    (record?.customerLastname ?? '').trim()

                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontWeight: 800, color: '#5A5A5A' }}>
                            {customer}
                        </div>
                        <div style={{ color: '#5A5A5A' }}>
                            {record?.criticalMomentDescription +
                                ' - ' +
                                record?.geoLocationDescription}
                        </div>
                    </div>
                )
            }

            return (
                <div style={{ color: '#5A5A5A' }}>
                    {record.customerCode}
                </div>
            )

        },
    },
    {
        id: 2,
        dataIndex: 'extra2',
        key: 'extra2',
        title: 'Satisfacción',
        width: 100,
        render: (value: any, record: any) => {
            const qrSatisfaction = record.questionResponses
                .find((item: any) => item.questionName === 'durante_a_pregunta_41'
                    || item.questionName === 'durante_pregunta_33'
                    || item.questionName === 'posterior_pregunta_40'
                    || item.questionName === 'posterior_a_pregunta_54')
            return qrSatisfaction !== undefined ? qrSatisfaction.numberAnswer : '-'
        },
    },
    {
        id: 3,
        dataIndex: 'extra3',
        key: 'extra3',
        title: 'NPS',
        width: 100,
        render: (value: any, record: any) => {
            const qrSatisfaction = record.questionResponses
                .find((item: any) => item.questionName === 'posterior_pregunta_2'
                    || item.questionName === 'posterior_a_pregunta_5'
                    || item.questionName === 'durante_pregunta_3'
                    || item.questionName === 'durante_a_pregunta_6')

            //console.log('Rendering custom column')
            //console.log(qrSatisfaction)
            return qrSatisfaction !== undefined ? qrSatisfaction.numberAnswer : '-'
        },
    },
    {
        id: 4,
        dataIndex: 'extra4',
        key: 'extra4',
        title: 'Comentario general',
        width: 250,
        render: (value: any, record: any) => {
            const qrComment = record.questionResponses
                .find((item: any) => item.questionName === 'durante_a_pregunta_43'
                    || item.questionName === 'durante_pregunta_9'
                    || item.questionName === 'posterior_pregunta_42'
                    || item.questionName === 'posterior_a_pregunta_55')
            return qrComment !== undefined ? qrComment.commentAnswer : '-'
        },
    },
    {
        id: 5,
        dataIndex: 'extra5',
        key: 'extra5',
        title: 'Estado',
        width: 100,
        render: (value: any, record: any) => {
            return <Badge
                style={{
                    backgroundColor:
                        record.status === 'ANSWERED' ? 'green' : 'red',
                }}
                className={`badge-${record.status.toLowerCase()}`}
            >
                {useClientTranslation().translate('surveys.' + record.status)}
            </Badge>
        },
    },
    {
        id: 6,
        dataIndex: 'createdAt',
        key: 'createdAt',
        title: 'Fecha',
        width: 80,
        className: 'actions-style-status-alerts',
        render: (value: any, record: any) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {/*<div style={{ color: "#959595" }}>{record?.clientId}</div>*/}
                    <div style={{ color: '#383838' }}>
                        {dayjs(value).format('DD/MM/YYYY HH:mm')}
                    </div>
                </div>
            )
        },
    },
    {
        id: 7,
        title: '',
        width: 60,
        dataIndex: 'id',
        key: 'detail-button',
        className: 'actions',
        onCell: (record: Record<string, any>) => ({
            onClick: () => { },
        }),
        render: (val: string, record: any) => (
            <Link
                key={`action-${record.id}`}
                to={`/survey-stats/detail/${record.id}`}
                style={{
                    backgroundColor: '#F9F9F9',
                    border: '1px solid #E9E9E9',
                    borderRadius: 50,
                    color: '#555555',
                    fontWeight: 500,
                    textDecoration: 'none',
                    maxWidth: '90px'
                }}
            >
                Detalles
            </Link>
        ),
    },
]