import { pieColors } from '../dashHelper'


export const widgetsSemicircle: CommonWidget = {

  /**************************************************************************
   *                                SEMICIRCLE
  ***************************************************************************/

  semicircleGeneric: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'semicircle-generic',
      type: 'semicircle',
      color: ['#CCC'],
      showHistoric: false,
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: [
            'passives',
            'detractors',
            'promoters',
            'passiveCount',
            'detractorCount',
            'promoterCount',
            'value',
            'groupName',
            'count'
          ],
          extras: {},
          label: 'group',
          value: 'value',
          singleton: false
        }
      ]
    }
  },
  
  countNumberSemicircle: {
    title: 'FALTA',
    position: [1, 0],
    size: [1, 2],
    config: {
      id: 'semi-circle-chart',
      type: 'semicircle',
      colors: ['#669D20', '#D6EE4B', '#FFD01B', '#FF0000', '#990001'],
      showHistoric: true,
      valueFormat: ' >-.0%',
      enableArcLabels: true,
      useGroupValue: 1,
      dataType: 'list',
      center: ['50%', '43%'],
      radius: ['35%', '80%'],
      legendOrient: 'vertical',
      fontSize: 30,
      itemHeight: 10,
      mapper: (data: any): any => {
        return data
      },
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['value', 'details'],
          label: 'group',
          value: 'value',
          extras: {
            groupNameFormat: 'description',
            model: 'avg',
            formula: 'count-number'
          }
        }
      ]
    }
  },
}
