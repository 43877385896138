import { COLORS_CONFIG_BAR, COLORS_CONFIG_GAUGE_5 } from '@components/widgets/apacheEchart/Commons'
import { answeredMapper, invalidMapper } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'

import { commonWidgets, } from './commonWidgetsByd'
import { bydCustomerJourneyServicioMapper, bydTooltipTotalEncuestas } from './cxoResume'

export default {
  title: 'Nacional Postventa Dashboard',
  description: 'Dashboard de Nacional Postventa',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      position: [0, 0],
      size: [1, 2],
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
      extraConfigs: {
        decimals: 1
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'semicircleGeneric',
      title: 'widgets.npsPostventa',
      position: [1, 0],
      size: [1, 2],
      indicator: 'SERVICIO_NPS',
      criticalMomentId: 2,
      extras: {
        periods: 6,
        decimals: 1
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      indicator: 'postventa-calidad-datos-gauge',
      url: 'survey-responses/values',
      position: [2, 0],
      size: [1, 2],
      mapper: invalidMapper,
      tooltipFormatter: bydTooltipTotalEncuestas,
      criticalMomentId: 2,
      extraConfigs: {
        decimals: 1
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      indicator: 'postventa-porc-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      tooltipFormatter: bydTooltipTotalEncuestas,
      // [Column, Row]
      position: [3, 0],
      size: [1, 2],
      criticalMomentId: 2,
      extraConfigs: {
        decimals: 1
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      position: [0, 2],
      size: [4, 2],
      indicator: 'SERVICIO_CUSTOMER_JOURNEY',
      mapper: bydCustomerJourneyServicioMapper,
      criticalMomentId: 2,
      extraConfigs: {
        decimals: 1,
        colors: COLORS_CONFIG_GAUGE_5,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      position: [0, 4],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [1, 4],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasPostventa',
      position: [2, 4],
      size: [1, 2],
      extraConfigs: {
        fontSize: 20
      },
      indicator: 'SERVICIO_ROTATIVAS',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasPostventa',
      position: [3, 4],
      size: [1, 2],
      extraConfigs: {
        fontSize: 20
      },
      indicator: 'SERVICIO_ROTATIVAS',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsPostventa',
      position: [0, 6],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: 2,
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsPostventa',
      position: [2, 6],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: 2,
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de sentimientos',
      indicator: 'SENTIMENT',
      criticalMomentId: 2,
      position: [0, 7],
      size: [4, 4],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'widgets.zonalSatisfaction',
      key: 'postventa-satisfaccion-zona',
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
      position: [0, 11],
      size: [4, 2],
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        wrapTexts: true,
      },
      extras: {
        groupByLevel: 2
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'widgets.distribuidorSatisfaction',
      key: 'postventa-satisfaccion-dist',
      indicator: 'SERVICIO_SATISFACCION',
      criticalMomentId: 2,
      position: [0, 13],
      size: [4, 2],
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        wrapTexts: true,
        rotateXAxisLegend: true
      },
      extras: {
        groupByLevel: 3,
      },
    }),
  ],
} as DasboardConfig
