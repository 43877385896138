
import logoBydSmall from '@assets/logos/byd-logo-small.png'
import logoByd from '@assets/logos/byd-logo.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import { omoikaneByd } from '../omoikane/omoikaneByd'

export const bydConfigs: ClientConfigInfo = {
    //vocBackendUrl: 'https://byd-backend-voc.limetropy.com/api/',
    // Epsilon back
    vocBackendUrl: 'https://byd-epsilon-backend-voc.limetropy.com/api/',
    lcmBackendUrl: '',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: undefined,
    logoSmall: logoBydSmall,
    logo: logoByd,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 0,
    decimals: undefined,
    supportsInternationalization: true,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    menuDashboards: [DASHBOARDS.DEFAULT],
    msExcelReportExt: 'xls',
    geosRootId: 10001,
    logicalsRootId: 0,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    geoLeafLevel: '',
    sentimentCommentsIndicatorName: 'sentiment-comments',
    omoikane: omoikaneByd,
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.byd,
        default: DASHBOARDS_CONFIGS.byd[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponsesUrl: 'survey-responses',
    surveyResponsesTableColumns: undefined,
    surveyResponsesTabs: [SurveyResponsesTab.ANSWERED, SurveyResponsesTab.NOT_ANSWERED]
}
