import { groupMapperLateral } from '../dashHelper'
import { commonWidgets } from './commonWidgetsOxxo'
import { getCommonWidget } from '../dashWidgetHelper'

export default {
  title: 'Demo Dashboard',
  description: 'Dashboard de perfil Demo',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'satisfaction',
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Satisfacción general',
      position: [0, 3],
      size: [1, 2],
      indicator: 'satisfaction',
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Indicador de Rapidez',
      position: [1, 3],
      size: [1, 2],
      indicator: 'service-speed',
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'trendByMonth',
      title: 'Indicador de Atención',
      position: [2, 3],
      size: [1, 2],
      indicator: 'service',
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'NPS',
      position: [3, 3],
      size: [1, 2],
      indicator: 'willingness-to-recommend',
      mapper: (data, extra) => {
        return data.map((item: any, idx: any) => ({
          ...item,
          groupName: item.groupName + ' (' + item.value + ')'
        }))
      },
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericLarge',
      title: 'Forma de pago',
      position: [0, 5],
      size: [4, 2],
      mapper: groupMapperLateral,
      indicator: 'OXXO_FORMA_PAGO',
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericLarge',
      title: 'Tipos de producto',
      position: [0, 7],
      size: [4, 2],
      indicator: 'OXXO_PRODUCTO',
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'Sentimiento positivo con más frecuencia',
      position: [0, 9],
      size: [2, 1],
      indicator: 'sentiment',
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'Sentimiento negativo con más frecuencia',
      position: [2, 9],
      size: [2, 1],
      indicator: 'sentiment',
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      position: [0, 10],
      size: [4, 4],
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lastComments',
      position: [0, 14],
      size: [2, 3],
      criticalMomentInclude: [11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [2, 14],
      size: [2, 3],
      criticalMomentInclude: [11703],
    }),
  ],
} as DasboardConfig
