
import logoMitsubishiSmall from '@assets/logos/mitsubishi-logo-small.png'
import logoMitsubishi from '@assets/logos/mitsubishi-logo.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getMitsubishiFilterCriticalMomentDefault, getMitsubishiFilterCriticalMoments } from '../dashboard/dashHelper'
import { omoikaneMitsubishi } from '../omoikane/omoikaneMitsubishi'

export const mitsubishiConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://mitsubishi.limetropy.com/api/',
    lcmBackendUrl: '',
    //backendUrl: 'http://localhost:9000/api/',
    publicUrl: undefined,
    logoSmall: logoMitsubishiSmall,
    logo: logoMitsubishi,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 1,
    decimals: undefined,
    supportsInternationalization: true,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    menuDashboards: [DASHBOARDS.DEFAULT],
    msExcelReportExt: 'xls',
    geosRootId: 10001,
    logicalsRootId: 0,
    criticalMomentsRootId: 0,
    criticalMomentFilterType: 'select',
    geoLeafLevel: '',
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    omoikane: omoikaneMitsubishi,
    getFilterCriticalMoments: getMitsubishiFilterCriticalMoments,
    getFilterCriticalMomentDefault: getMitsubishiFilterCriticalMomentDefault,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.mitsubishi,
        default: DASHBOARDS_CONFIGS.mitsubishi[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponsesUrl: 'survey-responses',
    surveyResponsesTableColumns: undefined,
    surveyResponsesTabs: [SurveyResponsesTab.ANSWERED, SurveyResponsesTab.NOT_ANSWERED]
}
