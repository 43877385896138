import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  lineDetailedByMonthAndGeo: {
    ...widgetsLibrary.lineDetailedByMonthAndGeo,
    //@ts-ignore
    config: {
      ...widgetsLibrary.lineDetailedByMonthAndGeo.config
    }
  },
}
