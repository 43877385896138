import { COLORS_CONFIG } from '@components/widgets/apacheEchart/Commons'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'


export const suzukiCustomPieMapper = (data: any) => {
  return data.map((item: any) => ({
    id: item["group"] == null ? "Indefinido" : item["group"],
    label: item["group"] == null ? "Indefinido" : item["group"],
    value: item["value"],
  }))
}

export const getCmID = (code: string): number => {
  if (code === 'venta') return 10651
  else if (code === 'servicio' || code === 'postventa') return 10652
  else return 1
}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  multipleGaugeByQuestion: {
    ...widgetsLibrary.multipleGaugeByQuestion,
    //@ts-ignore
    config: {
      ...widgetsLibrary.multipleGaugeByQuestion.config,
      colors: COLORS_CONFIG,
      maxValue: 5
    },
  }
}
