import { COLORS_CONFIG, COLORS_CONFIG_BAR } from '@components/widgets/apacheEchart/Commons'
import { answeredMapper, invalidMapper } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, } from './commonWidgetsMitsu'

export default {
  title: 'Nacional Postventa Dashboard',
  description: 'Dashboard de Nacional Postventa',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'lineDetailedByMonthAndGeo',
      title: 'Satisfacción General',
      position: [0, 0],
      size: [4, 3],
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentInclude: [2]
    }),
    //1
    {
      title: 'Encuestas',
      type: 'label',
      position: [0, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'Alertas',
      type: 'label',
      position: [2, 3],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'survey-response-counters',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'survey-response-counters',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByMonth',
      position: [2, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByWeek',
      position: [3, 4],
      size: [1, 2],
      indicator: 'alert-first-response-time',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPosventa',
      position: [0, 6],
      size: [1, 2],
      indicator: 'POSVENTA_NPS',
      criticalMomentId: 2,
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPosventa',
      position: [1, 6],
      size: [1, 2],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: [2, 6],
      size: [1, 2],
      mapper: invalidMapper,
      url: 'survey-responses/values',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      position: [3, 6],
      size: [1, 2],
      mapper: answeredMapper,
      url: 'survey-responses/values',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      position: [0, 8],
      size: [4, 2],
      indicator: 'POSVENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasPostventa',
      position: [0, 10],
      size: [2, 2],
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: 2
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasPostventa',
      position: [2, 10],
      size: [2, 2],
      indicator: 'POSVENTA_ROTATIVAS',
      criticalMomentId: 2
    }),
    //3
    {
      title: 'Oficinas',
      type: 'label',
      position: [0, 12],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de oficinas',
      position: [0, 13],
      size: [2, 2],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentInclude: [2, 11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsPostventa',
      position: [2, 13],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentInclude: [2, 11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsPostventa',
      position: [3, 13],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentInclude: [2, 11703],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsPostventa',
      position: [0, 15],
      size: [1, 2],
      indicator: 'sentiment',
      criticalMomentId: 2,
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsPostventa',
      position: [1, 15],
      size: [1, 2],
      indicator: 'sentiment',
      criticalMomentId: 2,
      extras: {
        resultType: 'bottom',
        trunc: 'HALF_UP',
        bottomSize: 3,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [2, 15],
      size: [2, 2],
      indicator: 'WORD_COUNT_WITH_LINKS',
      criticalMomentId: 2,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'widgets.sentimentAnalysis',
      position: [0, 17],
      size: [4, 4],
      criticalMomentId: 2,
    }),
    {
      title: 'widgets.zonalSatisfaction',
      // [Column, Row]
      position: [0, 21],
      size: [4, 2],
      config: {
        id: 'satisfaction-zona',
        type: 'bar',
        fillMode: '',
        //colors: '#33b9ce',
        colors: COLORS_CONFIG_BAR,
        showValues: true,
        percentageScale: false,
        indicators: [
          {
            indicator: 'POSVENTA_SATISFACCION',
            grouped: 'geoLocation',
            keyExtract: ['group', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupByLevel: 2,
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.distribuidorSatisfaction',
      // [Column, Row]
      position: [0, 23],
      size: [4, 2],
      config: {
        id: 'satisfaccion-dist',
        type: 'bar',
        fillMode: '',
        //colors: '#33b9ce',
        colors: COLORS_CONFIG_BAR,
        showValues: true,
        percentageScale: false,
        indicators: [
          {
            indicator: 'POSVENTA_SATISFACCION',
            grouped: 'geoLocation',
            keyExtract: ['group', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              groupByLevel: 3,
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'Diagrama Ishikawa',
      position: [0, 25],
      size: [4, 3],
      indicator: 'sentiment',
      criticalMomentId: 2,
    }),
    {
      title: 'widgets.otherIndicators',
      type: 'label',
      position: [0, 28],
      size: [4, 1],
    },
    {
      title: 'widgets.appointmentPostventa',
      // [Column, Row]
      position: [0, 29],
      size: [2, 2],
      config: {
        id: 'posventa-puntualidad',
        type: 'pie',
        colors: ['#015c52', '#33b9ce', '#f38625'],
        fillMode: '',
        showValues: true,
        //percentageScale: false,
        mapper: (data) => {
          return data.map((item) => ({
            id: item['group'] == null ? 'Indefinido' : item['group'],
            label: item['group'] == null ? 'Indefinido' : item['group'],
            value: item['value'],
          }))
        },
        indicators: [
          {
            indicator: 'POSVENTA_AGENDAR_CITA',
            //grouped: 'geoLocation',
            keyExtract: ['group', 'count', 'value'],
            label: 'group',
            value: 'value',
            singleton: true,
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.waitingRoomPostventa',
      // [Column, Row]
      position: [2, 29],
      size: [1, 2],
      config: {
        id: 'posventa-instalaciones',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'POSVENTA_INSTALACIONES',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.signagePostventa',
      // [Column, Row]
      position: [3, 29],
      size: [1, 2],
      config: {
        id: 'posventa-senaletica',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'POSVENTA_SENIALECTICA',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.paymentPostventa',
      // [Column, Row]
      position: [0, 31],
      size: [1, 2],
      config: {
        id: 'posventa-facturacion',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'POSVENTA_FACTURACION',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.substitutionCarPostventa',
      // [Column, Row]
      position: [1, 31],
      size: [1, 2],
      config: {
        id: 'posventa-vehiculo-sustitucion',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'POSVENTA_VEHICULO_SUSTITUCION',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.carStatusPostventa',
      // [Column, Row]
      position: [2, 31],
      size: [1, 2],
      config: {
        id: 'posventa-limpieza-vehiculo',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'POSVENTA_LIMPIEZA_VEHICULO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.repairPostventa',
      // [Column, Row]
      position: [3, 31],
      size: [1, 2],
      config: {
        id: 'posventa-reparacion',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'POSVENTA_REPARACION_VEHICULO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.repairAvailablePostventa',
      // [Column, Row]
      position: [0, 33],
      size: [1, 2],
      config: {
        id: 'posventa-disponibilidad',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'POSVENTA_DISPONIBILIDAD_TALLER',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.budgetPostventa',
      // [Column, Row]
      position: [1, 33],
      size: [1, 2],
      config: {
        id: 'posventa-presupuesto',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'POSVENTA_PRESUPUESTO',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
    {
      title: 'widgets.warrantyPostventa',
      // [Column, Row]
      position: [2, 33],
      size: [1, 2],
      config: {
        id: 'posventa-garantia',
        type: 'gauge',
        fillMode: '',
        //color: '#33b9ce',
        color: COLORS_CONFIG,
        showValues: true,
        maxValue: 5,
        decimals: 1,
        center: ['50%', '50%'],
        radius: '90%',
        indicators: [
          {
            indicator: 'POSVENTA_GARANTIA',
            keyExtract: ['value'],
            value: 'value',
            extras: {
              criticalMomentId: 2,
            },
          },
        ],
      },
    },
  ],
} as DasboardConfig
